export const Banners = () => import('../../components/Banners.vue' /* webpackChunkName: "components/banners" */).then(c => wrapFunctional(c.default || c))
export const BaseAlert = () => import('../../components/BaseAlert.vue' /* webpackChunkName: "components/base-alert" */).then(c => wrapFunctional(c.default || c))
export const BaseGallery = () => import('../../components/BaseGallery.vue' /* webpackChunkName: "components/base-gallery" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseItemPost = () => import('../../components/BaseItemPost.vue' /* webpackChunkName: "components/base-item-post" */).then(c => wrapFunctional(c.default || c))
export const BaseSlider = () => import('../../components/BaseSlider.vue' /* webpackChunkName: "components/base-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseSliderInner = () => import('../../components/BaseSliderInner.vue' /* webpackChunkName: "components/base-slider-inner" */).then(c => wrapFunctional(c.default || c))
export const BaseSliderSlide = () => import('../../components/BaseSliderSlide.vue' /* webpackChunkName: "components/base-slider-slide" */).then(c => wrapFunctional(c.default || c))
export const BaseSpinner = () => import('../../components/BaseSpinner.vue' /* webpackChunkName: "components/base-spinner" */).then(c => wrapFunctional(c.default || c))
export const BaseToast = () => import('../../components/BaseToast.vue' /* webpackChunkName: "components/base-toast" */).then(c => wrapFunctional(c.default || c))
export const CommentsList = () => import('../../components/CommentsList.vue' /* webpackChunkName: "components/comments-list" */).then(c => wrapFunctional(c.default || c))
export const CommentsListComment = () => import('../../components/CommentsListComment.vue' /* webpackChunkName: "components/comments-list-comment" */).then(c => wrapFunctional(c.default || c))
export const CommentsListForm = () => import('../../components/CommentsListForm.vue' /* webpackChunkName: "components/comments-list-form" */).then(c => wrapFunctional(c.default || c))
export const LayoutHero = () => import('../../components/LayoutHero.vue' /* webpackChunkName: "components/layout-hero" */).then(c => wrapFunctional(c.default || c))
export const ListItemInfo = () => import('../../components/ListItemInfo.vue' /* webpackChunkName: "components/list-item-info" */).then(c => wrapFunctional(c.default || c))
export const ListRelated = () => import('../../components/ListRelated.vue' /* webpackChunkName: "components/list-related" */).then(c => wrapFunctional(c.default || c))
export const ListShare = () => import('../../components/ListShare.vue' /* webpackChunkName: "components/list-share" */).then(c => wrapFunctional(c.default || c))
export const PostsList = () => import('../../components/PostsList.vue' /* webpackChunkName: "components/posts-list" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderCategories = () => import('../../components/TheHeaderCategories.vue' /* webpackChunkName: "components/the-header-categories" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderMenu = () => import('../../components/TheHeaderMenu.vue' /* webpackChunkName: "components/the-header-menu" */).then(c => wrapFunctional(c.default || c))
export const TheNotifications = () => import('../../components/TheNotifications.vue' /* webpackChunkName: "components/the-notifications" */).then(c => wrapFunctional(c.default || c))
export const ToastGdpr = () => import('../../components/ToastGdpr.vue' /* webpackChunkName: "components/toast-gdpr" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
