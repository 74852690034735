//
//
//
//
//
//

import { SITE } from '~/utils/constants';

export default {
    created() {
        this.SITE = SITE;
    },
};
