//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    layout: 'empty',

    props: {
        error: {
            type: Object,
            required: true,
        },
    },
};
