//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LayoutHero from '~/components/LayoutHero.vue';

export default {
    components: {
        LayoutHero,
    },

    computed: {
        data() {
            const pageKey = this.$route.fullPath;
            return this.$store.getters['data/currentPage'](pageKey);
        },
        singleFeaturedMedia() {
            if (this.$route.name !== 'Single') return false;

            return this.data.main?.featuredMedia;
        },
    },
};
