//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SITE } from '../utils/constants';

export default {
    props: {
        data: {
            type: Array,
            default: () => ([]),
        },
    },

    data: () => ({
        show: {
            navbarBodyMain: false,
        },
    }),

    created() {
        this.SITE = SITE;
    },

    methods: {
        menuToggle() {
            if (!this.show.navbarBodyMain) this.menuOpen();
            else this.menuClose();
        },
        menuOpen() {
            this.show.navbarBodyMain = true;
            this.$root.$el.addEventListener('click', this.onMenuOpenClick);
            this.$root.$el.addEventListener('keydown', this.onMenuOpenKeydown);
        },
        menuClose() {
            this.show.navbarBodyMain = false;
            this.$root.$el.removeEventListener('click', this.onMenuOpenClick);
            this.$root.$el.removeEventListener('keydown', this.onMenuOpenKeydown);
        },
        goToSearch($event) {
            const s = $event.target.elements.s.value;

            if (!s) return;

            this.$router.push({ path: '/', query: { s } });
            this.menuClose();
        },
        onMenuOpenClick(event) {
            const clickedTo = (classes) => classes
                .some((className) => event.target.classList.contains(className));

            if (!clickedTo(['navbar-toggler', 'navbar-toggler-icon', 'form-control'])) {
                this.menuClose();
            }
        },
        onMenuOpenKeydown(event) {
            if (['Escape', 'Esc'].includes(event.key)) {
                this.menuClose();
            }
        },
    },
};
