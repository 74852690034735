//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        data: {
            type: Array,
            default: () => ([]),
        },
    },
};
