import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=d9b84072&"
import script from "./TheHeader.vue?vue&type=script&lang=js&"
export * from "./TheHeader.vue?vue&type=script&lang=js&"
import style0 from "./TheHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeaderMenu: require('/home/runner/work/cabral.ro/cabral.ro/components/TheHeaderMenu.vue').default,TheHeaderCategories: require('/home/runner/work/cabral.ro/cabral.ro/components/TheHeaderCategories.vue').default})
