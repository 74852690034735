//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        img: {
            type: [Boolean, Object],
            default: false,
        },
    },
};
